import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import CTA from "../sections/nightshift/CTA";
import PostDetails from "../sections/blog/PostDetails";

import heroImg from  "../assets/nightshift/blog-kubernetes-scheduler.jpeg";

const BlogRegular = () => {
  return (
    <>
      <PageWrapper>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-left">
              <Col lg="10">
                <Title variant="hero" className="text-center">CI/CD: Contextual Pipelines</Title>
                <PostDetails 
                    heroImg={heroImg}
                    imgAlt="Password Security"
                >
                    A foreward to the reader: This content expects familiarity with what a pipeline is, how it works, and presumably you are curious about how to create more value with the existing investment. 

                    What is the value of a pipeline? Time, which <em>is</em> money. By removing manual processes in the software development lifecycle, more time can be spent on generating value, and less time on doing repetitive tasks, which happens to be an extremely good problem to task a machine with.

                    This is already compelling, but tends to fall short in a few ways. Imagine you have a pipeline that tests, performs analysis with SSAT toolkits, performs a build, perhaps ships security artifacts to another team, and optionally automatically deploys to QA.
                    Does this process know the difference between feature iteration and an attempt at a release? Do you really want to block a developer from observing current security posture in the event of a failing unit test?

                    This is where we come in. Our CI/CD Reference Architectures cater each stage of the development lifecycle to what is of most critical importance contextually. We provide developers rapid access to test results and posture analysis, regardless of what it is, while dynamically catering to unconditional security posture to go live.
                </PostDetails>
              </Col>
            </Row>
          </Container>
        </Section>
        <CTA />
      </PageWrapper>
    </>
  );
};
export default BlogRegular;
